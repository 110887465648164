import "./Services.scss";
import servicios from "./../../assets/catalogue/servicios/servicios.json";

export const Services = () => {
  return (
    <div className="row text-center text-primary">
      {servicios.data.map((d, index) => (
        <div key={index} className="col-md-6 col-lg-4 mb-5">
          <div className="row h-100 mx-3 mx-lg-5">
            <div className="col container-shadow p-0 border border-2 border-primary">
              <div className="row">
                <div className="col">
                  <img
                    src={require(`./../../assets/catalogue/servicios/${d.service.image}`)}
                    alt={d.service.name}
                    width="100%"
                  />
                </div>
              </div>
              <div className="row pt-4">
                <div className="col">
                  <h3 className="text-secondary">{d.service.name}</h3>
                </div>
              </div>
              <div className="row px-4 pb-4">
                <div className="col">
                  <p className="m-0">{d.service.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
