import "./Footer.scss";
import brand from "./../../../assets/brand.png";
import { ButtonTop } from "./../ButtonTop";
import { ButtonWhatsApp } from "./../ButtonWhatsApp";

export const Footer = () => {
  return (
    <div className="container-fluid bg-primary pt-3 text-white link-light">
      <ButtonTop />
      <ButtonWhatsApp />
      <div className="row">
        {/* Marca */}
        <div className="col-md-5 col-lg-3 text-center my-auto">
          {/* Logotipo */}
          <div className="row">
            <div className="col">
              <img src={brand} alt="brand" width={300} />
            </div>
          </div>
          {/* Redes sociales */}
          <div className="row">
            <div className="col">
              <a
                href="https://www.facebook.com/people/SUMAKC-SAS/100063787230583/?mibextid=LQQJ4d"
                target="_blank"
                rel="noreferrer"
                className="link-light"
              >
                <i className="bi bi-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/sumakc.sas/"
                target="_blank"
                rel="noreferrer"
                className="mx-5 link-light"
              >
                <i className="bi bi-instagram"></i>
              </a>
              <a href="#" target="_blank" className="link-light">
                <i className="bi bi-tiktok"></i>
              </a>
            </div>
          </div>
        </div>
        {/* Contactos */}
        <div className="col-md-4 offset-md-3 offset-lg-5 pt-4">
          {/* Titulo */}
          <div className="row mb-3">
            <div className="col">
              <h4>Contáctanos</h4>
            </div>
          </div>
          {/* WhatsApps */}
          <div className="row">
            <div className="col-lg-4 mb-3">
              <span>
                <i className="bi bi-whatsapp pe-2"></i>0959013343
              </span>
            </div>
            <div className="col-lg-4 mb-3">
              <span>
                <i className="bi bi-whatsapp pe-2"></i>0980207084
              </span>
            </div>
            <div className="col-lg-4 mb-3">
              <span>
                <i className="bi bi-whatsapp pe-2"></i>0968552290
              </span>
            </div>
          </div>
          {/* Direccion */}
          <div className="row">
            <div className="col">
              <p className="m-0">
                <i className="bi bi-house-door pe-2"></i>Av. Fundadores del
                cantón y héroes del 41, a unos pasos de la discoteca “MOJITO”
              </p>
              <p className="mb-0 text-center">Pillaro - Ecuador</p>
            </div>
          </div>
        </div>
      </div>
      <hr className="my-4" />
      <div className="row">
        <div className="col-md-3" style={{ opacity: 0.9 }}>
          <p className="m-0">
            &copy; {new Date().getFullYear()}
            <strong> Sumakc S.A.S.</strong>
          </p>
          <p>Todos los Derechos reservados</p>
        </div>
        {/* Powred by */}
        <div className="col-md-9 col-lg-6 text-center my-auto">
          <div className="powered-by mb-3">
            <a
              href="https://wallpanadev.com/"
              target="_blank"
              className="fw-bold text-decoration-none text-black"
            >
              Powered By
              <br />
              <span>WALLPANA</span>
              <span>
                DEV<span> S.A.S. B.I.C.</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
