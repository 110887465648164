import "./ButtonWhatsApp.scss";

export const ButtonWhatsApp = () => {
  return (
    <div>
      <div className="rounded-circle chat-circle shadow">
        <a href="http://wa.link/6lhut3" target="_blank" className="link-light">
          <i className="bi bi-whatsapp fs-1"></i>
        </a>
      </div>
    </div>
  );
};
