import "./Header.scss";
import logotipo from "./../../../assets/logotipo.png";

export const Header = (props) => {
  return (
    <div className="container-fluid bg-primary">
      <div className="container-page">
        <img src={logotipo} alt="logotipo" />
        <h2 className="text-white">{props.title}</h2>
      </div>
    </div>
  );
};
