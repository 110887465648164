import "./App.scss";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navbar } from "./components/shared/Navbar";
import { Footer } from "./components/shared/Footer";
import { Home } from "./pages/Home";
import { Catalogue } from "./pages/Catalogue";
import { Business } from "./pages/Business";

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/catalogo" element={<Catalogue />} />
          <Route path="/empresa" element={<Business />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
