import "./Business.scss";

import { Header } from "./../../components/shared/Header";

/* Icono */
import iconSecondary from "./../../assets/business/sumakc-icon-secondary.png";
/* Imagenes */
import nosotros from "./../../assets/business/nosotros.png";
import mision from "./../../assets/business/mision.png";
import vision1 from "./../../assets/business/vision1.png";
import vision2 from "./../../assets/business/vision2.png";

export const Business = () => {
  return (
    <div>
      <Header title="EMPRESA" />
      <div className="container-fluid">
        {/*=================== Seccion 1 ===================*/}
        <div className="row text-center py-5">
          <div className="col-11 m-auto">
            {/* Titulo */}
            <div className="row mb-5">
              <div className="col">
                <h1 className="text-secondary">NUESTRA EMPRESA</h1>
              </div>
            </div>
            {/* Informacion */}
            <div className="row">
              {/* Texto */}
              <div className="col-lg-5 m-auto">
                <div className="row mb-3">
                  <div className="col">
                    <h3 className="text-primary">Sobre Nosotros</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p className="text-primary">
                      La empresa SUMAKC S.A.S. fue fundada en 2013 es una
                      empresa con experiencia en la comercialización de llantas,
                      aros, baterías, tubos y accesorios de alta calidad. Donde
                      por años hemos sido reconocidos como una empresa líder,
                      innovadora y de alto desempeño entre las empresas de esta
                      misma industria. Es por eso que ponemos a su disposición
                      servicios complementarios de mecánica rápida como:
                      enllantaje, balanceo, rotación, cambio de pastillas de
                      freno. Además, ofrecemos un stock seleccionado de llantas,
                      aros y accesorios para si vehículo sin importar su
                      aplicación, es decir: vehículos de pasajeros, carga,
                      SUV&acute;s, off road, deportivos. Lo que nos convierte en
                      el lugar indicado para garantizar la satisfacción y
                      seguridad de nuestros clientes.
                    </p>
                  </div>
                </div>
              </div>
              {/* Img */}
              <div className="col-md-8 col-lg-6 mx-auto">
                <img
                  src={nosotros}
                  alt="nosotros"
                  width={"100%"}
                  className="border border-gray border-3 rounded-2"
                />
              </div>
            </div>
          </div>
        </div>
        {/*=================== Seccion 2 ===================*/}
        <div className="row bg-primary py-5">
          <div className="col-10 m-auto">
            {/* Titulo */}
            <div className="row text-center mb-5">
              <div className="col">
                <h1 className="text-secondary">NUESTRA MISIÓN</h1>
              </div>
            </div>
            {/* Informacion */}
            <div className="row">
              {/* Img */}
              <div className="col-md-5 mx-auto mb-4 mb-lg-0">
                <img src={mision} alt="mision" width={"100%"} />
              </div>
              {/* Txt */}
              <div className="col-lg-6 m-auto text-white text-justify fs-5">
                <div className="row mb-4">
                  <div className="col-2 col-md-1 m-auto">
                    <img
                      src={iconSecondary}
                      alt="iconSecondary"
                      width={"130%"}
                    />
                  </div>
                  <div className="col-10 col-md-11">
                    <p className="m-0">
                      Creemos en nuestra responsabilidad de dar plena
                      satisfacción a las necesidades de nuestros clientes.
                    </p>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-2 col-md-1 m-auto">
                    <img
                      src={iconSecondary}
                      alt="iconSecondary"
                      width={"130%"}
                    />
                  </div>
                  <div className="col-10 col-md-11">
                    <p className="m-0">
                      Creemos en una cultura de excelente servicio, como medio
                      esencial para exceder las expectativas de nuestros
                      clientes.
                    </p>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-2 col-md-1 ms-auto my-auto">
                    <img
                      src={iconSecondary}
                      alt="iconSecondary"
                      width={"130%"}
                    />
                  </div>
                  <div className="col-8">
                    <p className="m-0">
                      Creemos en los valores éticos y morales, como norma de
                      conducta en todas nuestras acciones.
                    </p>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-2 col-md-1 ms-auto my-auto">
                    <img
                      src={iconSecondary}
                      alt="iconSecondary"
                      width={"130%"}
                    />
                  </div>
                  <div className="col-8">
                    <p className="m-0">
                      Creemos en la necesidad de retribuir a los socios,
                      proporcionalmente a su inversión.
                    </p>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-2 col-md-1 m-auto">
                    <img
                      src={iconSecondary}
                      alt="iconSecondary"
                      width={"130%"}
                    />
                  </div>
                  <div className="col-10 col-md-11">
                    <p className="m-0">
                      Creemos en los derechos de nuestros proveedores y
                      asociados en el fiel cumplimiento de los pactos
                      establecidos.
                    </p>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-2 col-md-1 m-auto">
                    <img
                      src={iconSecondary}
                      alt="iconSecondary"
                      width={"130%"}
                    />
                  </div>
                  <div className="col-10 col-md-11">
                    <p className="m-0">
                      Creemos en el trabajo en equipo, en el respeto al ser
                      humano, en su desarrollo integral, en su autonomía, en la
                      justa recompensa a su esfuerzo, en la profesionalización
                      de cada una de las funciones con objetivos y metas
                      comunes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*=================== Seccion 3 ===================*/}
        <div className="row bg-light py-5">
          <div className="col-11 mx-auto">
            {/* Titulo */}
            <div className="row text-center mb-4">
              <div className="col">
                <h1 className="text-secondary">Nuestra Visión</h1>
              </div>
            </div>
            {/* Informacion */}
            <div className="row">
              <div className="col-10 col-md-5 col-lg-3 mx-auto mb-4 mb-lg-0">
                <img src={vision1} alt="vision1" width={"100%"} />
              </div>
              <div className="col-lg-4 mx-auto mb-4 m-lg-auto bg-white py-5 px-5 shadow">
                <h5 className="text-justify text-primary">
                  Proyectarnos como una Empresa de comercialización de llantas,
                  aros, baterías, tubos y accesorios plenamente reconocida en
                  Ecuador, direccionada a nuestros clientes, con productos y
                  servicios que excedan plenamente sus necesidades hacia el año
                  2023.
                </h5>
              </div>
              <div className="col-10 col-md-5 col-lg-3 mx-auto">
                <img src={vision2} alt="vision2" width={"100%"} />
              </div>
            </div>
          </div>
        </div>
        {/*=================== Seccion 4 ===================*/}
        <div className="row bg-primary py-5 text-center">
          <div className="col-lg-10 mx-auto">
            {/* Titulo */}
            <div className="row mb-5">
              <div className="col">
                <h1 className="text-white">Síguenos en nuestras redes</h1>
              </div>
            </div>
            {/* Redes */}
            <div className="row text-secondary">
              {/* Facebook */}
              <div className="col-md-4 position-relative">
                <div className="row mb-3">
                  <div className="col">
                    <i className="bi bi-facebook fs-1"></i>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <a
                      href="https://www.facebook.com/people/SUMAKC-SAS/100063787230583/?mibextid=LQQJ4d"
                      target="_blank"
                      rel="noreferrer"
                      className="link-light text-decoration-none stretched-link"
                    >
                      <h4>/SUMAKC SAS</h4>
                    </a>
                  </div>
                </div>
              </div>
              {/* Instagram */}
              <div className="col-md-4 position-relative">
                <div className="row mb-3">
                  <div className="col">
                    <i className="bi bi-instagram fs-1"></i>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <a
                      href="https://www.instagram.com/sumakc.sas/"
                      target="_blank"
                      rel="noreferrer"
                      className="link-light text-decoration-none stretched-link"
                    >
                      <h4>@sumakc.sas</h4>
                    </a>
                  </div>
                </div>
              </div>
              {/* TikTok */}
              <div className="col-md-4 position-relative">
                <div className="row mb-3">
                  <div className="col">
                    <i className="bi bi-tiktok fs-1"></i>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <a
                      href="#"
                      className="link-light text-decoration-none stretched-link"
                    >
                      <h4>@sumakc</h4>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*=================== Seccion 5 ===================*/}
        <div className="row py-5">
          <div className="col-11 col-lg-9 m-auto container-shadow py-3 px-5">
            {/* Titulo */}
            <div className="row mb-4 text-center">
              <div className="col">
                <h1 className="text-primary">Contactos</h1>
              </div>
            </div>
            {/* Informacion */}
            <div className="row">
              {/* Empresa */}
              <div className="col-lg-7 mx-auto mb-5 my-lg-auto">
                {/* Direccion */}
                <div className="row mb-4">
                  <div className="col-md-3 col-lg-2 m-auto text-center">
                    <i className="bi bi-house-door fs-1 text-secondary"></i>
                  </div>
                  <div className="col-md-9 col-lg-10">
                    <h5 className="text-primary text-justify">
                      Av. Fundadores del cantón y héroes del 41, a unos pasos de
                      la discoteca “MOJITO”
                    </h5>
                    <h5 className="m-0 text-primary text-center">
                      Pillaro - Ecuador
                    </h5>
                  </div>
                </div>
                {/* Titutlo Horario */}
                <div className="row mb-3">
                  <div className="col">
                    <h4 className="text-primary">Hoararios de Atención</h4>
                  </div>
                </div>
                {/* Horarios */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-2 m-auto text-center">
                        <i className="bi bi-calendar text-secondary fs-1"></i>
                      </div>
                      <div className="col-md-10 m-auto">
                        <h5 className="text-primary">Lunes a Viernes</h5>
                        <h5 className="m-0 text-primary">8:00 am - 6:30 pm</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-2 m-auto text-center">
                        <i className="bi bi-calendar text-secondary fs-1"></i>
                      </div>
                      <div className="col-md-10 m-auto">
                        <h5 className="text-primary">Sábados a Domingos</h5>
                        <h5 className="mb-0 text-primary">8:00 am - 3:30 pm</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* WhatsApp's */}
              <div className="col-md-3 text-center mx-auto ms-lg-auto my-lg-auto">
                <div className="row mb-3">
                  <div className="col">
                    <h5 className="text-primary wp-border">
                      <i className="bi bi-whatsapp pe-2 text-secondary fs-5"></i>
                      0959013343
                    </h5>
                    <span className="text-primary">Ejecutivo de ventas</span>
                    <h5 className="text-primary">PILLARO MATRIZ</h5>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <h5 className="text-primary wp-border">
                      <i className="bi bi-whatsapp pe-2 text-secondary fs-5"></i>
                      0980207084
                    </h5>
                    <span className="text-primary">Ejecutivo de ventas</span>
                    <h5 className="text-primary">PELILEO</h5>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <h5 className="text-primary wp-border">
                      <i className="bi bi-whatsapp pe-2 text-secondary fs-5"></i>
                      0968552290
                    </h5>
                    <span className="text-primary">Ejecutivo de ventas</span>
                    <h5 className="text-primary">PATATE</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
