import "./Catalogue.scss";
import { Header } from "./../../components/shared/Header";
import { Products } from "./../../components/Products";
import { Services } from "./../../components/Services";
import distribuidores from "./../../assets/catalogue/distribuidores/distribuidores.json";
/* Img Metodos de pagos electronico */
import visa from "./../../assets/catalogue/metodos-pago/visa.png";
import mastercard from "./../../assets/catalogue/metodos-pago/mastercard.png";
import pacificard from "./../../assets/catalogue/metodos-pago/pacificard.png";
import redypago from "./../../assets/catalogue/metodos-pago/redypago.png";
import payphone from "./../../assets/catalogue/metodos-pago/payphone.png";
import payvalida from "./../../assets/catalogue/metodos-pago/payvalida.png";
/* Img Metodos de pagos transferencia */
import bancoPichincha from "./../../assets/catalogue/metodos-pago/banco-pichincha.png";
import bancoPacifico from "./../../assets/catalogue/metodos-pago/banco-pacifico.png";
import visAndes from "./../../assets/catalogue/metodos-pago/vis-andes.png";

export const Catalogue = () => {
  const hashName = window.location.hash.replace("#", "");
  const hash = hashName && hashName.length > 0 ? hashName : "productos";
  const sizeDistribuidor = distribuidores.images.length;
  const widthLogtipo = 250;
  const animationSpeed = 70;

  const scrollStyle = (
    <style>
      {`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(calc(-${widthLogtipo}px * ${sizeDistribuidor}));
          }
        }
     `}
    </style>
  );

  const styles = {
    slideTrack: {
      animation: `scroll ${animationSpeed}s linear infinite`,
      display: "flex",
      width: `calc(${widthLogtipo} * ${sizeDistribuidor * 2})`,
    },
  };

  return (
    <div>
      {scrollStyle}
      <Header title={"PRODUCTOS Y SERVICIOS"} />
      <div className="container-fluid">
        {/*=================== Seccion 1 ===================*/}
        <div className="row py-5">
          <div className="col">
            {/* Titulo */}
            <div className="row text-center mb-5">
              <div className="col">
                <h1 className="text-primary">NUESTRO CATÁLOGO</h1>
              </div>
            </div>
            {/* Catalogo */}
            <div className="row">
              <div className="col">
                {/* Tab */}
                <ul
                  className="nav nav-pills mb-5 justify-content-center"
                  id="pills-tab"
                  role="tablist"
                >
                  {/* Productos */}
                  <li className="nav-item me-5" role="presentation">
                    <button
                      className={`nav-link ${
                        hash === "productos" ? "active" : ""
                      }`}
                      id="pills-products-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-products"
                      type="button"
                      role="tab"
                      aria-controls="pills-products"
                      aria-selected={hash === "productos"}
                    >
                      <h6 className="m-0">Productos</h6>
                    </button>
                  </li>
                  {/* Servicios */}
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        hash === "servicios" ? "active" : ""
                      }`}
                      id="pills-services-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-services"
                      type="button"
                      role="tab"
                      aria-controls="pills-services"
                      aria-selected={hash === "servicios"}
                    >
                      <h6 className="m-0">Servicios</h6>
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  {/* Productos */}
                  <div
                    className={`tab-pane fade ${
                      hash === "productos" ? "show active" : ""
                    }`}
                    id="pills-products"
                    role="tabpanel"
                    aria-labelledby="pills-products-tab"
                    tabIndex="0"
                  >
                    <Products />
                  </div>
                  {/* Servicios */}
                  <div
                    className={`tab-pane fade ${
                      hash === "servicios" ? "show active" : ""
                    }`}
                    id="pills-services"
                    role="tabpanel"
                    aria-labelledby="pills-services-tab"
                    tabIndex="0"
                  >
                    <Services />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*=================== Seccion 2 ===================*/}
        <div className="row bg-gray bg-opacity-25 py-5">
          <div className="col">
            <div className="row text-center mb-4">
              <div className="col">
                <h1 className="text-secondary">Distribuidores Autorizados</h1>
              </div>
            </div>
            <div className="row">
              <div className="col p-0">
                <div className="slider">
                  <div style={styles.slideTrack}>
                    {distribuidores.images.map((imgName, index) => (
                      <div key={index} className="slide">
                        <img
                          src={require(`./../../assets/catalogue/distribuidores/${imgName}.png`)}
                          alt={imgName}
                        />
                      </div>
                    ))}
                    {distribuidores.images.map((imgName, index) => (
                      <div key={index} className="slide">
                        <img
                          src={require(`./../../assets/catalogue/distribuidores/${imgName}.png`)}
                          alt={imgName}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*=================== Seccion 3 ===================*/}
        <div className="row bg-light py-5 text-primary">
          <div className="col">
            {/* Titulo */}
            <div className="row mb-4">
              <div className="col">
                <h1 className="text-center">Métodos de Pago</h1>
              </div>
            </div>
            {/* Informacion */}
            <div className="row">
              {/* Electronico */}
              <div className="col-10 col-lg-5 bg-white mx-auto mb-3 text-center px-4 py-5">
                {/* Titulo */}
                <div className="row mb-3">
                  <div className="col">
                    <h4>TARJETAS DE CRÉDITO</h4>
                  </div>
                </div>
                {/* Logo tarjetas */}
                <div className="row mb-4">
                  <div className="col-5 col-md-2 m-auto">
                    <img src={visa} alt="visa" width={"100%"} />
                  </div>
                  <div className="col-4 col-md-2 m-auto">
                    <img src={mastercard} alt="mastercard" width={"100%"} />
                  </div>
                  <div className="col-6 col-md-3 m-md-auto mt-3 mx-auto">
                    <img src={pacificard} alt="pacificard" width={"100%"} />
                  </div>
                </div>
                {/* Seguridad */}
                <div className="row mb-4">
                  <div className="col-md-5 text-start my-auto">
                    <h5>Con la seguridad de:</h5>
                  </div>
                  <div className="col-7 col-md-3">
                    <img src={redypago} alt="redypago" width={"100%"} />
                  </div>
                </div>
                {/* Pagos digitales */}
                <div className="row mb-2">
                  <div className="col">
                    <h4>PAGOS DIGITALES</h4>
                  </div>
                </div>
                {/* Logotipos */}
                <div className="row">
                  <div className="col-9 col-md-5 m-auto">
                    <img src={payphone} alt="payphone" width={"100%"} />
                  </div>
                  <div className="col-9 col-md-4 m-auto">
                    <img src={payvalida} alt="payvalida" width={"100%"} />
                  </div>
                </div>
              </div>
              {/* Transferencias */}
              <div className="col-10 col-lg-5 bg-white m-auto text-center px-4 py-5">
                {/* Titulo */}
                <div className="row mb-3">
                  <div className="col">
                    <h4>TRANSFERENCIAS - DÉPOSITOS BANCARIOS</h4>
                  </div>
                </div>
                {/* Bancos */}
                <div className="row mb-4">
                  {/* Pichincha */}
                  <div className="col-md-4 m-auto">
                    <div className="row mb-2">
                      <div className="col-7 col-md-10 m-auto">
                        <img
                          src={bancoPichincha}
                          alt="bancoPichincha"
                          width={"100%"}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <h6 className="text-primary">Cuenta Corriente</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <h6 className="text-primary">#2100254044</h6>
                      </div>
                    </div>
                  </div>
                  {/* Pacifico */}
                  <div className="col-md-4 m-auto">
                    <div className="row mb-2">
                      <div className="col-9 col-md-12 m-auto">
                        <img
                          src={bancoPacifico}
                          alt="bancoPacifico"
                          width={"100%"}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <h6 className="text-primary">Cuenta Corriente</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <h6 className="text-primary">#8156921</h6>
                      </div>
                    </div>
                  </div>
                  {/* Andes */}
                  <div className="col-md-4 m-auto">
                    <div className="row mb-2">
                      <div className="col-7 col-md-10 m-auto">
                        <img src={visAndes} alt="visAndes" width={"100%"} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <h6 className="text-primary">Cuenta Corriente</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <h6 className="text-primary">#440600019585</h6>
                      </div>
                    </div>
                  </div>
                </div>
                {/* A nombre de */}
                <div className="row mb-4">
                  <div className="col text-start">
                    <h5>A nombre de:</h5>
                  </div>
                </div>
                {/* Datos */}
                <div className="row">
                  <div className="col">
                    <h5>SUMAKC S.A.S</h5>
                    <h6 className="m-0">Ruc: 1891809162001</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*=================== Seccion 4 ===================*/}
        <div className="row py-5 text-primary">
          <div className="col">
            <div className="row mb-4">
              <div className="col">
                <h1 className="text-center">Contáctanos</h1>
              </div>
            </div>
            <div className="row text-center text-md-start">
              {/* Empresa */}
              <div className="col-10 col-lg-5 m-lg-auto mx-auto mb-5 container-shadow p-5">
                {/* Direccion */}
                <div className="row mb-4">
                  <div className="col-md-3 col-lg-2 m-auto text-center">
                    <i className="bi bi-house-door fs-1 text-secondary"></i>
                  </div>
                  <div className="col-md-9 col-lg-10">
                    <h6 className="text-primary text-justify">
                      Av. Fundadores del cantón y héroes del 41, a unos pasos de
                      la discoteca “MOJITO”
                    </h6>
                    <h6 className="m-0 text-primary text-center">
                      Pillaro - Ecuador
                    </h6>
                  </div>
                </div>
                {/* Titutlo Horario */}
                <div className="row mb-3">
                  <div className="col">
                    <h5 className="text-primary">Hoararios de Atención</h5>
                  </div>
                </div>
                {/* Horarios */}
                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-3 m-auto text-center">
                        <i className="bi bi-calendar text-secondary fs-1"></i>
                      </div>
                      <div className="col-md-9 m-auto">
                        <h6 className="text-primary">Lunes a Viernes</h6>
                        <h6 className="m-0 text-primary">8:00 am - 6:30 pm</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-3 m-auto text-center">
                        <i className="bi bi-calendar text-secondary fs-1"></i>
                      </div>
                      <div className="col-md-9 m-auto">
                        <h6 className="text-primary">Sábados a Domingos</h6>
                        <h6 className="mb-0 text-primary">8:00 am - 3:30 pm</h6>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Titulo */}
                <div className="row mb-2">
                  <div className="col">
                    <h5 className="text-primary">O escríbenos al:</h5>
                  </div>
                </div>
                {/* wps */}
                <div className="row">
                  <div className="col-md-4">
                    <h6 className="text-primary">
                      <i className="bi bi-whatsapp pe-2 text-secondary fs-5"></i>
                      0959013343
                    </h6>
                  </div>
                  <div className="col-md-4">
                    <h6 className="text-primary">
                      <i className="bi bi-whatsapp pe-2 text-secondary fs-5"></i>
                      0980207084
                    </h6>
                  </div>
                  <div className="col-md-4">
                    <h6 className="text-primary">
                      <i className="bi bi-whatsapp pe-2 text-secondary fs-5"></i>
                      0968552290
                    </h6>
                  </div>
                </div>
              </div>
              {/* Mapa */}
              <div className="col-10 col-lg-5 mx-auto">
                <div className="w-100 h-100 border border-gray border-2 border-opacity-50 rounded-2">
                  <iframe
                    title="Sumakc"
                    width="100%"
                    height="100%"
                    scrolling="no"
                    src="https://maps.google.com/maps?width=100%25&amp;height=100%&amp;hl=en&amp;q=Sumakc+(Sumakc)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
