import "./Navbar.scss";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo.png";

export const Navbar = () => {
  useEffect(() => {
    const currentUrl = window.location.pathname.split("/").pop();

    document.querySelectorAll(".custom-active").forEach((element) => {
      if (currentUrl === element.href.split("/").pop()) {
        element.classList.add("link-secondary");
      }
    });
  }, []);

  return (
    <nav className="navbar navbar-expand-lg bg-primary">
      <div className="container-fluid pt-3">
        <a href="/" className="navbar-brand">
          <img src={logo} alt="logo" width="210" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse pt-4"
          id="navbarSupportedContent"
        >
          {/* menu */}
          <ul className="navbar-nav mx-auto">
            <li className="nav-item me-5">
              <a href="/" className="nav-link font-inter custom-active me-5">
                Inicio
              </a>
            </li>
            <li className="nav-item me-5">
              <a
                href="/empresa"
                className="nav-link font-inter custom-active me-5"
              >
                Empresa
              </a>
            </li>
            <li className="nav-item">
              <a href="/catalogo" className="nav-link font-inter custom-active">
                Servicios
              </a>
            </li>
          </ul>
          {/* Redes Sociales */}
          <ul className="navbar-nav" style={{ flexDirection: "initial" }}>
            <li className="nav-item">
              <a
                href="https://www.facebook.com/people/SUMAKC-SAS/100063787230583/?mibextid=LQQJ4d"
                target="_blank"
                rel="noreferrer"
                className="nav-link link-light"
              >
                <i className="bi bi-facebook"></i>
              </a>
            </li>
            <li className="nav-item mx-5 mx-lg-0">
              <a
                href="https://www.instagram.com/sumakc.sas/"
                target="_blank"
                rel="noreferrer"
                className="nav-link link-light"
              >
                <i className="bi bi-instagram"></i>
              </a>
            </li>
            <li className="nav-item">
              <Link to="#" className="nav-link link-light">
                <i className="bi bi-tiktok"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
