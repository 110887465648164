import "./Home.scss";
import { HeaderHome } from "./../../components/HeaderHome";
import creditoDirecto from "./../../assets/home/credito-directo.svg";
/* Img Seccion 1 */
import nosotros from "./../../assets/home/nosotros.png";
import mision from "./../../assets/home/mision.png";
import vision from "./../../assets/home/vision.png";
/* Img Seccion 2 */
import balanceo from "./../../assets/home/balanceo.png";
import rotacion from "./../../assets/home/rotacion.png";
import montaje from "./../../assets/home/montaje.png";
/* Img Seccion 3 */
import accesorios from "./../../assets/home/accesorios.png";
import llantas from "./../../assets/home/llantas.png";
import aros from "./../../assets/home/aros.png";

export const Home = () => {
  return (
    <div>
      <HeaderHome />
      <div className="container-fluid">
        {/*=================== Credito directo ===================*/}
        <div className="row">
          <div className="col-11 col-md-7 col-lg-4 m-auto">
            <div className="row bg-white shadow rounded-2 py-4 ps-4 pe-5 container-cd">
              <div className="col m-auto">
                <h1 className="m-0 text-primary">Crédito Directo</h1>
              </div>
              <div className="col-2 my-auto p-0">
                <img
                  className="img-cd"
                  src={creditoDirecto}
                  alt="creditoDirecto"
                  width={"100%"}
                />
              </div>
            </div>
          </div>
        </div>
        {/*=================== Seccion 1 ===================*/}
        <div className="row">
          {/* Nuestra empresa */}
          <div className="col-md-10 col-lg-9 mx-auto text-center px-5 pt-3 pt-lg-5 pb-5 container-business">
            {/* Titulo */}
            <div className="row mb-5">
              <div className="col">
                <h1 className="text-secondary">Nuestra empresa</h1>
              </div>
            </div>
            <div className="row px-5 mb-3">
              {/* Nosotros */}
              <div className="col-md-6 col-lg-4">
                <div className="row mb-4">
                  <div className="col">
                    <img src={nosotros} alt="nosotros" width={"75%"} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <h4 className="text-primary">Sobre Nosotros</h4>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <p className="text-primary">
                      La empresa SUMAKC S.A.S. fue fundada en 2013 es una
                      empresa con experiencia en la comercialización de llantas,
                      aros, baterías, tubos y accesorios de alta calidad.
                    </p>
                  </div>
                </div>
              </div>
              {/* Mision */}
              <div className="col-md-6 col-lg-4">
                <div className="row mb-4">
                  <div className="col">
                    <img src={mision} alt="mision" width={"75%"} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <h4 className="text-primary">Nuestra Misión</h4>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <p className="text-primary">
                      Creemos en nuestra responsabilidad de dar plena
                      satisfacción a las necesidades de nuestros clientes.
                    </p>
                  </div>
                </div>
              </div>
              {/* Vision */}
              <div className="col-md-6 col-lg-4 mx-auto">
                <div className="row mb-4">
                  <div className="col">
                    <img src={vision} alt="vision" width={"75%"} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <h4 className="text-primary">Nuestra Visión</h4>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <p className="text-primary">
                      Proyectarnos como una Empresa de comercialización de
                      llantas, aros, baterías, tubos y accesorios plenamente
                      reconocida en Ecuador.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Boton */}
            <div className="row">
              <div className="col">
                <a
                  href="/empresa"
                  className="btn btn-secondary text-white rounded-0 px-4 py-3"
                >
                  <h5 className="m-0">Conoce más</h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*=================== Seccion 2 ===================*/}
        <div className="row bg-primary text-center section-2">
          <div className="col-9 m-auto">
            {/* Titutlo */}
            <div className="row mb-4">
              <div className="col">
                <h1 className="text-secondary">Nuestros Servicios</h1>
              </div>
            </div>
            {/* Servicios */}
            <div className="row mb-3 mb-lg-5">
              {/* Servicio 1 */}
              <div className="col-md-5 col-lg-3 mx-auto mb-4 m-lg-auto container-service">
                <div className="row">
                  <div className="col">
                    <img src={balanceo} alt="balanceo" width={"100%"} />
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col">
                    <h4 className="text-primary">Balanceo</h4>
                  </div>
                </div>
              </div>
              {/* Servicio 2 */}
              <div className="col-md-5 col-lg-4 mx-auto mb-4 m-lg-auto container-service">
                <div className="row">
                  <div className="col">
                    <img src={rotacion} alt="rotacion" width={"100%"} />
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col">
                    <h4 className="text-primary">Rotación</h4>
                  </div>
                </div>
              </div>
              {/* Servicio 3 */}
              <div className="col-md-5 col-lg-3 mx-auto mb-4 m-lg-auto container-service">
                <div className="row">
                  <div className="col">
                    <img src={montaje} alt="montaje" width={"100%"} />
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col">
                    <h4 className="text-primary">Montaje</h4>
                  </div>
                </div>
              </div>
            </div>
            {/* Boton */}
            <div className="row">
              <div className="col">
                <a
                  href="/catalogo#servicios"
                  className="btn btn-secondary text-white rounded-0 px-5 py-3"
                >
                  <h5 className="m-0">Ver Todos</h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*=================== Seccion 3 ===================*/}
        <div className="row bg-secondary text-center py-5">
          <div className="col-9 m-auto">
            {/* Titutlo */}
            <div className="row mb-5">
              <div className="col">
                <h1 className="text-primary">Nuestros Productos</h1>
              </div>
            </div>
            {/* Productos */}
            <div className="row mb-3 mb-lg-5">
              {/* Producto 1 */}
              <div className="col-md-5 col-lg-3 mx-auto mb-4 m-lg-auto container-service">
                <div className="row">
                  <div className="col">
                    <img src={accesorios} alt="accesorios" width={"100%"} />
                  </div>
                </div>
                <div className="row p-3">
                  <div className="col">
                    <h4 className="text-primary">Accesorios</h4>
                    <p className="text-primary">
                      Accesorios como válvulas, tuercas, tubos y defensas para
                      todo tipo de carro.
                    </p>
                  </div>
                </div>
              </div>
              {/* Producto 2 */}
              <div className="col-md-5 col-lg-4 mx-auto mb-4 m-lg-auto container-service">
                <div className="row">
                  <div className="col">
                    <img src={llantas} alt="llantas" width={"100%"} />
                  </div>
                </div>
                <div className="row p-3">
                  <div className="col">
                    <h4 className="text-primary">Llantas</h4>
                    <p className="text-primary">
                      Para todo tipo de auto, camioneta, camión o bus.
                    </p>
                  </div>
                </div>
              </div>
              {/* Producto 3 */}
              <div className="col-md-5 col-lg-3 mx-auto mb-4 m-lg-auto container-service">
                <div className="row">
                  <div className="col">
                    <img src={aros} alt="aros" width={"100%"} />
                  </div>
                </div>
                <div className="row p-3">
                  <div className="col">
                    <h4 className="text-primary">Aros</h4>
                    <p className="text-primary">
                      Aros de distintas marcas y características.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Boton */}
            <div className="row">
              <div className="col">
                <a
                  href="/catalogo#productos"
                  className="btn btn-primary rounded-0 px-5 py-3"
                >
                  <h5 className="m-0">Ver Todos</h5>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* ================================ Secciones 4 y 5 ================================ */}
        <div className="row bg-light text-center">
          <div className="col">
            {/*=================== Seccion 4 ===================*/}
            <div className="row py-5">
              {/* Contactos */}
              <div className="col-md-8 col-lg-5 mx-auto bg-white p-5 shadow mb-4 mb-lg-0">
                {/* Titulo */}
                <div className="row mb-4">
                  <div className="col">
                    <h3 className="text-primary">Contactos</h3>
                  </div>
                </div>
                {/* Direccion */}
                <div className="row mb-4">
                  <div className="col-md-3 col-lg-2 m-auto">
                    <i className="bi bi-house-door fs-1 text-secondary"></i>
                  </div>
                  <div className="col-md-9 col-lg-10">
                    <h6 className="text-primary text-justify">
                      Av. Fundadores del cantón y héroes del 41, a unos pasos de
                      la discoteca “MOJITO”
                    </h6>
                    <h6 className="m-0 text-primary text-center">
                      Pillaro - Ecuador
                    </h6>
                  </div>
                </div>
                {/* WhatsApp's */}
                <div className="row">
                  <div className="col-lg-4">
                    <h6 className="text-primary wp-border">
                      <i className="bi bi-whatsapp pe-2 text-secondary fs-5"></i>
                      0959013343
                    </h6>
                    <span className="text-primary">Ejecutivo de ventas</span>
                    <h6 className="text-primary">PILLARO MATRIZ</h6>
                  </div>
                  <div className="col-lg-4">
                    <h6 className="text-primary wp-border">
                      <i className="bi bi-whatsapp pe-2 text-secondary fs-5"></i>
                      0980207084
                    </h6>
                    <span className="text-primary">Ejecutivo de ventas</span>
                    <h6 className="text-primary">PELILEO</h6>
                  </div>
                  <div className="col-lg-4">
                    <h6 className="text-primary wp-border">
                      <i className="bi bi-whatsapp pe-2 text-secondary fs-5"></i>
                      0968552290
                    </h6>
                    <span className="text-primary">Ejecutivo de ventas</span>
                    <h6 className="text-primary">PATATE</h6>
                  </div>
                </div>
              </div>
              {/* Horarios */}
              <div className="col-md-8 col-lg-5 m-auto bg-white p-5 shadow text-start">
                <div className="row text-center mb-4">
                  <div className="col">
                    <h3 className="text-primary">Horarios de Atención</h3>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-2 m-auto">
                    <i className="bi bi-calendar text-secondary fs-1"></i>
                  </div>
                  <div className="col-10 m-auto">
                    <h6 className="text-primary">Lunes a Viernes</h6>
                    <h6 className="text-primary">8:00 am - 6:30 pm</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-2 m-auto">
                    <i className="bi bi-calendar text-secondary fs-1"></i>
                  </div>
                  <div className="col-10 m-auto">
                    <h6 className="text-primary">Sábados a Domingos</h6>
                    <h6 className="text-primary">8:00 am - 3:30 pm</h6>
                  </div>
                </div>
              </div>
            </div>
            {/*=================== Seccion 5 ===================*/}
            <div className="row pb-5">
              <div className="col-11 m-auto shadow">
                <div className="w-100">
                  <iframe
                    title="Sumakc"
                    width="100%"
                    height="600"
                    scrolling="no"
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Sumakc+(Sumakc)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
