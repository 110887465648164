import "./Products.scss";
import productos from "./../../assets/catalogue/productos/productos.json";

export const Products = () => {
  return productos.data.map((d, index) => (
    <div key={index} className="row">
      <div className="col">
        {/* Categoria */}
        <div className="row">
          <div className="col ms-2 ms-lg-3">
            <h4 className="text-primary m-0">{d.category.name}</h4>
          </div>
        </div>
        {/* Marcas */}
        <div className="row my-5">
          {d.category.brands.map((brand, i) => (
            <div
              key={i}
              className="col-4 col-md-2 col-lg-1 ms-3 ms-lg-4 mb-3 my-lg-auto py-1 border border-2 border-secondary rounded-1 d-flex"
            >
              <h6 className="text-secondary m-auto text-center">{brand}</h6>
            </div>
          ))}
        </div>
        {/* Items */}
        <div className="row mb-5">
          {d.category.data.map((dp, i) => (
            <div key={i} className="col-md-6 col-lg-4 mb-4">
              <div className="row h-100 mx-2 mx-lg-3 container-shadow">
                <div
                  className={`col-5 d-flex p-4 ${
                    ((i % 3) + i) % 2 === 0 ? "bg-primary" : "bg-secondary"
                  }`}
                >
                  <img
                    className="m-auto"
                    src={require(`./../../assets/catalogue/productos/${dp.product.image}`)}
                    alt={dp.product.image}
                    width={"100%"}
                  />
                </div>
                <div className="col-7 text-primary m-auto py-4 px-4 text-center">
                  <h5
                    className={`mb-3 ${
                      ((i % 3) + i) % 2 === 0
                        ? "text-secondary"
                        : "text-primary"
                    }`}
                  >
                    {dp.product.name}
                  </h5>
                  <p className="mb-5">{dp.product.description}</p>
                  <p className="description-product">{dp.product.type}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ));
};
