import "./HeaderHome.scss";
import brand from "../../assets/brand.png";

export const HeaderHome = () => {
  return (
    <div className="container-fuild bg-primary">
      <div className="front-page">
        <img src={brand} alt="brand" className="brand-img" />
      </div>
    </div>
  );
};
